
import {Vue, Component} from 'vue-property-decorator';
import axios from "axios";

const client = axios.create({
  baseURL: process.env.VUE_APP_APIGW_URL,
})
const customConfig = {
  headers: {
    'Content-Type': 'application/json'
  }
}

@Component({})
export default class Register extends Vue {
  $refs!: {
    form: HTMLFormElement
    submit: HTMLButtonElement
  }

  expTypeArray = ["R 타입"]
  orgArray = ["강남 세브란스 병원 (A)"]
  genderArray = ["모름", "남성", "여성"]

  serviceType = 0
  expType = 0
  org = 0
  birth = ""
  gender = 0
  contact = ""

  disabled = false
  result = ""
  error = null

  reset(): void {
    this.serviceType = 0
    this.expType = 0
    this.org = 0
    this.birth = ""
    this.contact = ""
    this.gender = 0
    this.result = ""
    this.error = null
  }

  validate(): boolean {
    return (this.serviceType != 0)
  }

  submit(): void {
    if (this.validate()) {
      this.disabled = true

      switch (this.serviceType) {
        case 1:
          this.registerAttendee()
          break
        case 2:
          this.registerTestAttendee()
          break
      }

    }
  }

  copy(): void {
    navigator.clipboard.writeText(this.result)
  }

  registerTestAttendee(): void {
    client.get(
        '/alx-dtx/v1/attendee/test/register.do', customConfig
    ).then((r) => {
      if (r.data) {
        console.log("received test attendee " + JSON.stringify(r.data))

        this.result = r.data["data"]["prescription"] + " | " + r.data["data"]["confirmCode"]
        this.error = null
      }
      this.disabled = false
    }).catch((error) => {
      this.disabled = false
      if (error.response) {
        if (error.response.status == 418) {
          this.error = error.response.data?.message
          console.log(error.response.data)
        } else {
          this.error = error
        }
      } else {
        console.log(error.message)
        this.error = error
      }
    })
  }

  registerAttendee(): void {
    let exOrder = "AX01"
    let exType = ""
    switch (this.expType) {
      case 0:
        exType += "R"
        break
    }

    let exOrg = ""
    switch (this.org) {
      case 0:
        exOrg += "A"
        break
    }

    const requestBody = JSON.stringify({
      exOrder: exOrder,
      exType: exType,
      exOrg: exOrg,
      subjectType: this.gender,
      gender: this.gender,
      birthYear: this.birth,
      contact: this.contact
    })

    console.log("request: ", requestBody)

    client.post(
        "/alx-dtx/v1/attendee/register.do", requestBody, customConfig
    ).then((r) => {
      if (r.data) {
        console.log("received response " +  JSON.stringify(r.data))

        this.result = r.data["data"]["prescription"] + " | " + r.data["data"]["confirmCode"]
        this.error = null
      }
      this.disabled = false
    }).catch((error) => {
      this.disabled = false
      if (error.response) {
        if (error.response.status == 418) {
          this.error = error.response.data?.message
          console.log(error.response.data)
        } else {
          this.error = error
        }
      } else {
        console.log(error.message)
        this.error = error
      }
    })
  }

}
